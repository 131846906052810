import Vue from "vue";
import { Message, Loading } from "element-ui";

/**
 * 调用接口数据
 * @param {*} url 接口url
 * @param {*} requestParams 相关参数
 * @param {*} method 方法名称
 * @param {*} options 其他参数
 * @returns
 */

export function randomNumber() {
  // 生成 最小值 到 最大值 区间的随机数
  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
  if (arguments.length === 1) {
    let [length] = arguments;
    // 生成指定长度的随机数字，首位一定不是 0
    let nums = [...Array(length).keys()].map((i) =>
      i > 0 ? random(0, 9) : random(1, 9)
    );
    return parseInt(nums.join(""));
  } else if (arguments.length >= 2) {
    let [min, max] = arguments;
    return random(min, max);
  } else {
    return Number.NaN;
  }
}
/**
 * 随机生成字符串
 * @param length 字符串的长度
 * @param chats 可选字符串区间（只会生成传入的字符串中的字符）
 * @return string 生成的字符串
 */
export function randomString(length, chats) {
  if (!length) length = 1;
  if (!chats) chats = "0123456789qwertyuioplkjhgfdsazxcvbnm";
  let str = "";
  for (let i = 0; i < length; i++) {
    let num = randomNumber(0, chats.length - 1);
    str += chats[num];
  }
  return str;
}

/**
 * 从sessionStorage中获取指定键的值
 * @param {*} length
 * @param {*} chats
 * @returns
 */
export function getItem(key) {
  try {
    const value = sessionStorage.getItem(key);
    return isJSONString(value) ? JSON.parse(value) : value;
  } catch (error) {
    console.error(`${error}`);
    return null;
  }
}

/**
 * 将键值对存储到sessionStorage中
 * @param {*} length
 * @param {*} chats
 * @returns
 */
export function setItem(key, value) {
  try {
    const serializedValue =
      typeof value === "object" ? JSON.stringify(value) : value;
    sessionStorage.setItem(key, serializedValue);
  } catch (error) {
    console.error(`${error}`);
  }
}

/**
 * 从sessionStorage中移除指定键的值
 * @param {*} length
 * @param {*} chats
 * @returns
 */
export function removeItem(key) {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    console.error(` ${error}`);
  }
}

//检查字符串是否是JSON格式
function isJSONString(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * 重新加载主题
 * @param {*} themeName 主题名称
 * @returns
 */
export function loadTheme(themeName) {
  if (!themeName) return;
  const head = document.getElementsByTagName("head")[0];
  const link = document.getElementById("theme-style");
  if (link) {
    link.href = `/theme/${themeName}.css`;
  } else {
    const newLink = document.createElement("link");
    newLink.id = "theme-style";
    newLink.rel = "stylesheet";
    newLink.href = `/theme/${themeName}.css`;
    head.appendChild(newLink);
  }
}

/**
 * 重新加载风格
 * @param {*} styleName 风格名称
 * @returns
 */
export function loadStyle(styleName) {
  if (!styleName) return;
  const head = document.getElementsByTagName("head")[0];
  const link = document.getElementById("item-style");
  if (link) {
    link.href = `/style/${styleName}.css`;
  } else {
    const newLink = document.createElement("link");
    newLink.id = "item-style";
    newLink.rel = "stylesheet";
    newLink.href = `/style/${styleName}.css`;
    head.appendChild(newLink);
  }
}

/**
 * 统一封装消息提示，适用于简单提示
 * @param {*} message 消息内容
 * @param {*} type 消息类型
 * @param {*} options 其他参数
 */
export function message(message, type, options) {
  Message({
    type: type || "success",
    message,
    ...options,
  });
}

Vue.prototype.getItem = getItem;
Vue.prototype.setItem = setItem;
Vue.prototype.removeItem = removeItem;
Vue.prototype.loadTheme = loadTheme;
Vue.prototype.loadStyle = loadStyle;
Vue.prototype.message = message;

Vue.prototype.calcAge = function(birthday) {
	var birthdayDate = new Date(birthday);
	var currentDate = new Date();

	var age = currentDate.getFullYear() - birthdayDate.getFullYear();
	var monthDifference = currentDate.getMonth() - birthdayDate.getMonth();

	// 如果当前月份小于出生月份或者在同一个月，但是当前日期小于出生日期
	if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < birthdayDate.getDate())) {
		age--;
	}

	// 计算月份
	var months;
	if (currentDate.getMonth() >= birthdayDate.getMonth()) {
		months = currentDate.getMonth() - birthdayDate.getMonth();
	} else {
		months = 12 - (birthdayDate.getMonth() - currentDate.getMonth());
	}


	return {
		years: age,
		months: months
	};
}

Vue.prototype.calcHeight = function(years, months, sex, myHeight) {

	const heightArrOld = sex ? getItem("gHeight") : getItem("bHeight");
	if(years === 0){
		years = 1;
	}
	const height1 = heightArrOld[years - 1];
	const height2 = heightArrOld[years];
	let hInfo = {};

	if (!height2) {
		hInfo = height2;
	} else {
		Object.keys(height1).forEach((e, index) => {
			if (e === 'high' || e === 'littleHigh' || e === 'normal' || e === 'littleShort' || e ===
				'shortField') {
				hInfo[e] = parseFloat((height1[e] + (height2[e] - height1[e]) * this.age.months /
					12
				).toFixed(1));
			}

		})
		console.log(hInfo);
	}
	const normalHeight = hInfo['normal'];
	let yearHeight = 0;
	if (sex) {
		//女生
		if (years < 2) {
			yearHeight = '25-30'
		} else if (years < 4) {
			yearHeight = '6-8'
		} else if (years < 7) {
			yearHeight = '5-7'
		} else if (years < 11) {
			yearHeight = '5-7'
		} else if (years < 13) {
			yearHeight = '6-10'
		} else if (years < 16) {
			yearHeight = '5-9'
		} else {
			yearHeight = '1-6'
		}
	} else {
		//男生
		if (years < 2) {
			yearHeight = '25-30'
		} else if (years < 4) {
			yearHeight = '6-8'
		} else if (years < 7) {
			yearHeight = '5-7'
		} else if (years < 13) {
			yearHeight = '5-7'
		} else if (years < 15) {
			yearHeight = '8-12'
		} else if (years < 18) {
			yearHeight = '6-10'
		} else {
			yearHeight = '2-6'
		}
	}
	let hText = '';
	let hNum = 1;
	if (myHeight <= hInfo.shortField) {
		hText = '矮小';
		hNum = 1;
	} else if (myHeight <= hInfo.littleShort) {
		hText = '偏矮';
		hNum = 2;
	} else if (myHeight < hInfo.littleHigh) {
		hText = '正常';
		hNum = 3;
	} else if (myHeight < hInfo.high) {
		hText = '偏高';
		hNum = 4;
	} else {
		hText = '高';
		hNum = 5;
	}

	let bfb = 0; //百分比
	const h1 = hInfo.high;
	const h2 = hInfo.shortField;
	const hc = ((h1 - h2) / 4).toFixed(1); //每份差值
	const oneH = ((h1 - h2) / 100).toFixed(2); //每1%的数值
	const scaleInfo = [{
		name: '高',
		bfb: 0,
		number: hInfo.high
	}, {
		name: '偏高',
		bfb: 1 - ((hInfo.littleHigh - hInfo.shortField) / oneH).toFixed(0),
		number: hInfo.littleHigh
	}, {
		name: '标准',
		bfb: 1 - ((hInfo.normal - hInfo.shortField) / oneH).toFixed(0),
		number: hInfo.normal
	}, {
		name: '偏矮',
		bfb: 1 - ((hInfo.littleShort - hInfo.shortField) / oneH).toFixed(0),
		number: hInfo.littleShort
	}, {
		name: '矮',
		bfb: 100,
		number: hInfo.shortField
	}];

	if (myHeight <= h2) {
		bfb = 0;
	} else if (myHeight >= h1) {
		bfb = 100;
	} else {
		bfb = ((myHeight - h2) / oneH).toFixed(0);
	}
	const params = {
		myHeight,
		normalHeight,
		chaHeight: Math.abs(myHeight - normalHeight).toFixed(1),
		isMore: (myHeight - normalHeight) > 0 ? true : false,
		yearHeight,
		hText,
		heightArr: [h1, (h1 - hc).toFixed(1), (h1 - hc * 2).toFixed(1), (h1 - hc * 3).toFixed(1), h2],
		bfb: 100 - bfb,
		hNum,
		scaleInfo
	};
	return params;
}

Vue.prototype.calcWeight = function(years, months, sex, myWeight) {

	const weightArrOld = sex ? getItem("gWeight") : getItem("bWeight");
	if(years === 0){
		years = 1;
	}
	const weight1 = weightArrOld[years - 1];
	const weight2 = weightArrOld[years];
	let wInfo = {};
	if (!weight2) {
		wInfo = weight2;
	} else {
		Object.keys(weight1).forEach((e, index) => {
			if (e === 'fat' || e === 'littleFat' || e === 'normal' || e === 'littleThin') {
				wInfo[e] = parseFloat((weight1[e] + (weight2[e] - weight1[e]) * this.age.months /
					12
				).toFixed(1));
			}
	
		})
		console.log(wInfo);
	}
	
	const normalWeight = wInfo['normal'];
	
	let yearWeight = 0;
	if (sex) {
		//女生
		if (years < 2) {
			yearWeight = '2-3'
		} else if (years < 4) {
			yearWeight = '1-1.5'
		} else if (years < 7) {
			yearWeight = '1-1.5'
		} else if (years < 11) {
			yearWeight = '0.5-1'
		} else if (years < 13) {
			yearWeight = '1.5-4'
		} else if (years < 16) {
			yearWeight = '1.5-4'
		} else {
			yearWeight = '1.5-4'
		}
	} else {
		//男生
		if (years < 2) {
			yearWeight = '2.5-3.5'
		} else if (years < 4) {
			yearWeight = '1.5-2'
		} else if (years < 7) {
			yearWeight = '1.5-2'
		} else if (years < 13) {
			yearWeight = '0.5-1'
		} else if (years < 15) {
			yearWeight = '2-5'
		} else if (years < 18) {
			yearWeight = '2-5'
		} else {
			yearWeight = '2-5'
		}
	}
	// const wInfo = weightArr[years - 1];
	let wText = '';
	let wNum = 1;
	if (myWeight <= wInfo.littleThin) {
		wText = '偏瘦';
		wNum = 1;
	} else if (myWeight < wInfo.littleFat) {
		wText = '正常';
		wNum = 2;
	} else if (myWeight < wInfo.fat) {
		wText = '偏重';
		wNum = 3;
	} else {
		wText = '肥胖';
		wNum = 4;
	}
	let deg = 0; //角度
	const w1 = wInfo.fat;
	const w2 = wInfo.littleThin;
	const wc = ((w1 - w2) / 4).toFixed(1); //每份差值
	const oneW = ((w1 - w2) / 180).toFixed(2); //每度的数值
	
	const scaleInfo = [{
		name: '胖',
		deg: 0,
		number: wInfo.fat
	}, {
		name: '偏胖',
		deg: 1 - ((wInfo.littleFat - wInfo.littleThin) / oneW).toFixed(0),
		number: wInfo.littleFat
	}, {
		name: '正常',
		deg: 1 - ((wInfo.normal - wInfo.littleThin) / oneW).toFixed(0),
		number: wInfo.normal
	}, {
		name: '偏瘦',
		deg: 180,
		number: wInfo.littleThin
	}];
	
	if (myWeight <= w2) {
		deg = 0;
	} else if (myWeight >= w1) {
		deg = 180;
	} else {
		deg = ((myWeight - w2) / oneW).toFixed(0);
	}

	const params = {
		myWeight,
		normalWeight,
		chaHeight: Math.abs(myWeight - normalWeight).toFixed(1),
		isMore: (myWeight - normalWeight) > 0 ? true : false,
		yearWeight,
		wText,
		weightArr: [w1, (w1 - wc).toFixed(1), (w1 - wc * 2).toFixed(1), (w1 - wc * 3).toFixed(1), w2],
		deg: 180 - deg, //需要取反
		wNum,
		scaleInfo
	};
	return params;
}

export default { randomString };
