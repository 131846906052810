import Vue from "vue";

function addZero(val) {
  if (val < 10) {
    return "0" + val;
  } else {
    return val;
  }
}

//格式化时间
Vue.filter("formatTime", function (value, type="YMD") {
  if (!value) return null;
  var dataTime = "";
  var data = new Date(value);
  var year = data.getFullYear();
  var month = addZero(data.getMonth() + 1);
  var day = addZero(data.getDate());
  var hour = addZero(data.getHours());
  var minute = addZero(data.getMinutes());
  var second = addZero(data.getSeconds());
  if (type == "YMD") {
    dataTime = year + "-" + month + "-" + day;
  } else if (type == "YMDHMS") {
    dataTime =
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
  } else if (type == "HMS") {
    dataTime = hour + ":" + minute + ":" + second;
  } else if (type == "YM") {
    dataTime = year + "-" + month;
  }
  return dataTime; //将格式化后的字符串输出到前端显示
});

//用户状态status
Vue.filter("status", function (status) {
  if (status == -1) return "审核不通过";
  else if (status == 0) return "审核中";
  else if (status == 1) return "审核通过";
});

//会员类型
Vue.filter("vip", function (opt) {
  if (opt == 0) return "非会员";
  else if (opt == 1) return "普通会员";
  else if (opt == 2) return "超级会员";
});

//数据来源
Vue.filter("sourceType", function (opt) {
  if (opt == 0) return "平台自建";
  else if (opt == 1) return "后台导入";
});

//性别
Vue.filter("sex", function (opt) {
  if (opt == 0) return "男";
  else if (opt == 1) return "女";
});

//年龄阶段
Vue.filter("ageName", function(age, sex) {
	if (!age) return "婴儿期";
	if (sex) {
		//女生
		if (age < 2) {
			return '婴儿期'
		} else if (age < 4) {
			return '幼儿期'
		} else if (age < 7) {
			return '学龄前期'
		} else if (age < 11) {
			return '学龄期'
		} else if (age < 13) {
			return '青春期初期'
		} else if (age < 16) {
			return '青春期中期'
		} else {
			return '青春期后期'
		}
	} else {
		//男生
		if (age < 2) {
			return '婴儿期'
		} else if (age < 4) {
			return '幼儿期'
		} else if (age < 7) {
			return '学龄前期'
		} else if (age < 13) {
			return '学龄期'
		} else if (age < 15) {
			return '青春期初期'
		} else if (age < 18) {
			return '青春期中期'
		} else {
			return '青春期后期'
		}
	}

});
